import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./pages/Home"
import ClientLogin from './pages/ClientLogin';
import ClientDash from "./pages/ClientDash";
import Clock from './pages/Clock';
import AdminLogin from './pages/AdminLogin';
import AdminDash from './pages/AdminDash';

function App() {
  // Reload Clock Every Midnight , Without Go Out Of Full - Screen
  const [key, setKey] = useState(0);
  useEffect(() => {
    const resetComponent = () => {
      console.log("les git it");
      
      setKey(prevKey => prevKey + 1); // Increment key to remount the component
    };

    // Calculate time until midnight
    const now = new Date();
    const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    const timeUntilMidnight = midnight - now;

    // Set a timeout to trigger reset at midnight
    const timeout = setTimeout(() => {
      resetComponent();
      // Set an interval to reset every subsequent midnight
      setInterval(resetComponent, 24 * 60 * 60 * 1000); // reset every 24 hours
    }, timeUntilMidnight);

    return () => clearTimeout(timeout); // Cleanup timeout on unmount
  }, []);

  return (
    <div>
      <Router>
        <Routes>
          {/* Home Page  */}
          <Route path='*' index element={<Home/>}></Route>

          {/* Admin Login + Dash */}
          <Route path="/admin/login" element={<AdminLogin/>} />
          <Route path="/admin" element={<AdminDash/>} />

          {/* Client Login + Dash */}
          <Route path="/client/login" element={<ClientLogin/>} />
          <Route path="/client/:emailUserName" element={<ClientDash/>} />

          {/* Masjid Clock */}
          <Route path="/masjid/:masjidName" element={<Clock key={key}/>} />
        </Routes>
      </Router>
  </div>
  )
}

export default App
