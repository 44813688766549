import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./style/main.css"
import withClearCache from "./components/ClearCache";

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root'));

const ClearCacheComponent = withClearCache(App);

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      window.location.reload();
    }
  },
});

root.render(
  <React.StrictMode>
    {/* <ClearCacheComponent /> */}
    <App/>
  </React.StrictMode>
);

