import React, { useState, useEffect, useRef } from 'react';

import { ref, get } from 'firebase/database';
import { useNavigate, useParams } from 'react-router-dom';

import { IoMenu } from 'react-icons/io5';

import { db } from '../firebase'; // Import your Firebase config
import "../style/clock.css";

import { useMediaQuery } from 'react-responsive'

import { MdLogout, MdOutlineClose, MdOutlineMosque } from 'react-icons/md';
import { FiVolume2, FiVolumeX } from 'react-icons/fi';

import clickSound from "../assets/click.mp3"
import { Helmet } from 'react-helmet';



import PrayTimes from "../PrayTimes"

function Clock() {
  const { masjidName } = useParams(); // Retrieve emailUserName from URL
  const navigate = useNavigate(); // To redirect if needed
  const clockRef = useRef(null); // Reference to the clock component for full-screen
  const adhanAudioRef = useRef(null); // Ref to the adhan audio element

  // State variables for mosque data
  const [msqName, setMsqName] = useState('');
  const [backgroundUrl, setBackgroundUrl] = useState('');
  const [adhanUrl, setAdhanUrl] = useState('');
  const [hijriDate, setHijriDate] = useState("");
  const [gregorianDate, setGregorianDate] = useState(""); // State for Gregorian date
  const [currentTime, setCurrentTime] = useState(""); // State for real-time clock
  const [ads, setads] = useState('')
  const [hadeeth, sethadeeth] = useState('')
  const [lat, setLat] = useState('');
  const [long, setLong] = useState('');
  const [nextSalet, setnextSalet] = useState(1)
  const [highlightedSalet, sethighlightedSalet] = useState(1)
  const [fajr, setFajr] = useState('');
  const [sunrise, setSunrise] = useState(''); 
  const [dhuhr, setDhuhr] = useState('');
  const [asr, setAsr] = useState('');
  const [maghrib, setMaghrib] = useState('');
  const [isha, setIsha] = useState('');
  const [nextAdhenIn, setnextAdhenIn] = useState("")
  const [prayerTimes, setPrayerTimes] = useState({});
  const [establishmentTimes, setEstablishmentTimes] = useState({});
  const [screen, setscreen] = useState(null)
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })
  const [saletDuration, setSaletDuration] = useState({})
  const [toggleMenu, settoggleMenu] = useState(false)
  const [soundActive, setsoundActive] = useState(false)
  const [showWamidh, setshowWamidh] = useState(false)
  
  const [firstLoad, setfirstLoad] = useState(true)
  // Toggle Full Screen
  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      clockRef.current.requestFullscreen().catch(err => {
        console.error(`Error attempting to enable full-screen mode: ${err.message}`);
      });
    } else {
      document.exitFullscreen();
    }
  };

  // Next Adhen Time
  const getNextAdhanTime = (prayerTimes) => {
    const now = new Date();

    // Check if prayerTimes is not empty
    if (!Object.keys(prayerTimes).length) {
      console.error('Prayer times not available yet');
      return null;
    }

    const prayerTimesToday = Object.values(prayerTimes).map(time => {
      if (!time) return null; // Handle null or undefined times
      const [hours, minutes] = time.split(':').map(Number);
      const adhanTime = new Date(now);
      adhanTime.setHours(hours % 12 + (hours >= 12 ? 12 : 0), minutes, 0, 0); // Convert to 24-hour format
      return adhanTime;
    }).filter(adhanTime => adhanTime !== null); // Filter out null values

    // Filter out any prayer times that have already passed today
    const futurePrayerTimes = prayerTimesToday.filter(adhanTime => adhanTime > now);

    
    if (futurePrayerTimes.length === 0) {
      // If all prayer times have passed today, set next Adhan to Fajr of the next day
      const [fajrHours, fajrMinutes] = prayerTimes?.Fajr?.split(':')?.map(Number) || [];
      if (fajrHours !== undefined && fajrMinutes !== undefined) {
        const nextDayFajr = new Date(now);
        nextDayFajr.setDate(nextDayFajr.getDate() + 1);
        nextDayFajr.setHours(fajrHours % 12 + (fajrHours >= 12 ? 12 : 0), fajrMinutes, 0, 0);
        setnextSalet(1)
        return nextDayFajr;
      }
      return null; // Handle case where Fajr time is not available
    }
    
    setnextSalet(7 - futurePrayerTimes.length)
    if (firstLoad) {
      sethighlightedSalet(7 - futurePrayerTimes.length)
      setfirstLoad(false)
    }
    return futurePrayerTimes[0]; // Return the closest upcoming Adhan time
  };

  // Calculate establishment times
  const calculateEstablishmentTimes = (apiResponse) => {
    const timings = apiResponse;
    
    // Default establishment offsets in minutes (customize as needed)
    const offsets = {
      Fajr: fajr,    // DF 10 minutes after adhan for Fajr
      Sunrise: sunrise, // DF 10 minutes after adhan for Sunrise
      Dhuhr: dhuhr,   // DF 15 minutes after adhan for Dhuhr
      Asr: asr,     // DF 10 minutes after adhan for Asr
      Maghrib: maghrib,  // DF 5 minutes after adhan for Maghrib
      Isha: isha     // DF 15 minutes after adhan for Isha
    };

    const newEstablishmentTimes = {};
    for (const [prayer, time] of Object.entries(timings)) {
      

      const [hours, minutes] = time.split(':').map(Number);
      const adhanTime = new Date();
      adhanTime.setHours(hours % 12 + (hours >= 12 ? 12 : 0), minutes, 0, 0);

      // Add the offset (establishment time)
      adhanTime.setMinutes(adhanTime.getMinutes() + offsets[prayer]);

      // Format the establishment time as HH:MM in 12-hour format without AM/PM
      const hours12 = adhanTime.getHours() % 12 || 12; // Convert to 12-hour format
      const minutesFormatted = String(adhanTime.getMinutes()).padStart(2, '0');

      newEstablishmentTimes[prayer] = `${hours12}:${minutesFormatted}`; // Removed AM/PM
    }

    setEstablishmentTimes(newEstablishmentTimes);
  };
  
  // Schedule the next Adhan using setInterval
  const adhanIntervalRef = useRef(null);  // Store the interval ID to clear it later
  const scheduleNextAdhan = (prayerTimes) => {
    const checkNextAdhan = () => {
      const nextAdhanTime = getNextAdhanTime(prayerTimes);  // Assuming this function is defined elsewhere

      
      if (nextAdhanTime) {
        const now = new Date();
        const timeUntilNextAdhan = nextAdhanTime - now;

        if (timeUntilNextAdhan <= 1000 && timeUntilNextAdhan >= 0) {
          console.log("It's time for the Adhan:", nextAdhanTime.toLocaleTimeString());

          if (adhanAudioRef.current) {
            setshowWamidh(true);
            const duration = adhanAudioRef.current.duration * 1000;

            // Set timeout to hide Wamidh after the audio finishes
            setTimeout(() => {
              setshowWamidh(false);
            }, duration);

            var durationTillIkama = 0;
            switch (highlightedSalet) {
              case 1:
                durationTillIkama = fajr * 60 * 1000
                break;
              case 2 :
                durationTillIkama= sunrise * 60 *1000
                 break
              case 3 :
                durationTillIkama = dhuhr * 60 * 1000
                break
              case 4 :
                durationTillIkama = asr * 60 *1000
                break
              case 5 :
                durationTillIkama = maghrib * 60 * 1000;
                break
              case 6 :
                durationTillIkama = isha * 60 * 1000
                break
              default:
                break;
            }
            
            if(highlightedSalet !== 2 ){
              setTimeout(() => {
                document.getElementById("blackscreen").style.display = "flex"
              
                
                setTimeout(() => {
                  document.getElementById('blackscreen').style.display = "none"
                }, saletDuration[Object.keys(saletDuration)[nextSalet-1]]* 60 * 1000);
              }, durationTillIkama);
            }
            

            adhanAudioRef.current.play()
              .then(() => {
                console.log("Adhan audio played successfully.");
              })
              .catch(error => {
                console.log(`Adhan audio failed to play: ${error.message}`);
                setshowWamidh(false);
              })
              
          }
        } else {
          // Display time remaining until the next Adhan
          const hoursLeft = Math.floor(timeUntilNextAdhan / 3600000);
          const minutesLeft = Math.floor((timeUntilNextAdhan % 3600000) / 60000);
          const secondsLeft = Math.floor((timeUntilNextAdhan % 60000) / 1000);

          setnextAdhenIn(` الوقت المتبقي ${secondsLeft} : ${minutesLeft} : ${hoursLeft}`);
        }
      }
    };

    // Clear previous interval if any before setting a new one
    if (adhanIntervalRef.current) {
      clearInterval(adhanIntervalRef.current);
    }

    // Set interval to check every second
    adhanIntervalRef.current = setInterval(checkNextAdhan, 1000);
  };
  
  // Convert time to 12-hour format without AM/PM
  const convertTo12HourFormat = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    const hours12 = hours % 12 || 12; // Convert to 12-hour format
    const minutesFormatted = String(minutes).padStart(2, '0');
    return `${hours12}:${minutesFormatted}`; // Format as HH:MM
  };

  // Get Hirji Date Intl
  const fetchHijriDate = () => {
    const today = new Date();

    // Format the date using the 'islamic' calendar in Arabic
    const hijriFormatter = new Intl.DateTimeFormat('ar-u-ca-islamic', { dateStyle: 'full' });

    // Format the date using the Gregorian calendar in English
    const gregorianFormatter = new Intl.DateTimeFormat('en', { dateStyle: 'full' });

    // Get Hijri date
    const formattedHijriDate = hijriFormatter.format(today);
    
    // Get Gregorian date
    const formattedGregorianDate = gregorianFormatter.format(today);

    // Set the Hijri and Gregorian dates in state (assuming you have useState for both)
    setHijriDate(formattedHijriDate);
    setGregorianDate(formattedGregorianDate);
  };

  // Fetch Clock Data From Firebase
  useEffect(() => {
    const fetchData = async () => {
      const refRec = ref(db, `users/${masjidName}`);
      
      try {
        const snapshot = await get(refRec);
        if (snapshot.exists()) {
          const data = snapshot.val();
          setMsqName(data.msqname || '');
          setLat(data.lat || '');
          setLong(data.long || '');
          setBackgroundUrl(data.backgroundImage || '');
          setAdhanUrl(data.adhan || '');
          setads(data.advertisement || '');
          sethadeeth(data.hadeeth || '');
          setscreen(data.screen?.toUpperCase() || '');
          setSaletDuration(data.saletDuration || {
            fajr: 15,
            sunrise: 15,
            dhuher: 15,
            asr: 15,
            maghrib: 15,
            isha: 15
          })
          setFajr(Number(data.fajr) || '');
          setSunrise(Number(data.sunrise) || '');
          setDhuhr(Number(data.dhuhr) || '');
          setAsr(Number(data.asr) || '');
          setMaghrib(Number(data.maghrib) || '');
          setIsha(Number(data.isha) || '');

          // Save data to local storage
          localStorage.setItem('masjidData', JSON.stringify(data));

          // Cache adhan sound and background image
          await cacheAssets(data.adhan, data.backgroundImage);
          
          
          // Fetch Hijri date and prayer times using lat and long
          fetchHijriDate(data.lat, data.long);
          
        } else {
          console.error('User not found in Firebase');
          navigate('/masjid/test');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        navigate('/masjid/test');
      }
    };

    const cacheAssets = async (adhanUrl, backgroundUrl) => {
      if ('caches' in window) {
        const cache = await caches.open('dynamic-cache');
        
        if (adhanUrl) {
          try {
            const response = await fetch(adhanUrl, { mode: 'no-cors' });
            await cache.put(adhanUrl, response.clone());
            console.log('Cached adhan URL');
          } catch (err) {
            console.error('Failed to fetch or cache adhan:', err);
          }
        }
    
        if (backgroundUrl) {
          try {
            const response = await fetch(backgroundUrl, { mode: 'no-cors' });
            await cache.put(backgroundUrl, response.clone());
            console.log('Cached background URL');
          } catch (err) {
            console.error('Failed to fetch or cache background:', err);
          }
        }
      }
    };
    // Check if online and fetch data
    const handleFetch = () => {
      if (navigator.onLine) {
        fetchData();
      } else {
        // If offline, retrieve data from local storage
        const savedData = localStorage.getItem('masjidData');
        if (savedData) {
          const data = JSON.parse(savedData);
          setMsqName(data.msqname || '');
          setLat(data.lat || '');
          setLong(data.long || '');
          setBackgroundUrl(data.backgroundImage || '');
          setAdhanUrl(data.adhan || '');
          setads(data.advertisement || '');
          sethadeeth(data.hadeeth || '');
          setscreen(data.screen?.toUpperCase() || '');

          setFajr(Number(data.fajr) || '');
          setSunrise(Number(data.sunrise) || '');
          setDhuhr(Number(data.dhuhr) || '');
          setAsr(Number(data.asr) || '');
          setMaghrib(Number(data.maghrib) || '');
          setIsha(Number(data.isha) || '');
          // Fetch Hijri date and prayer times using lat and long
          fetchHijriDate(data.lat, data.long);
        } else {
          console.error('No data available in local storage');
          navigate('/masjid/test');
        }
      }
    };

    handleFetch();

    // Optionally, listen for online/offline events
    window.addEventListener('online', handleFetch);
    window.addEventListener('offline', handleFetch);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('online', handleFetch);
      window.removeEventListener('offline', handleFetch);
    };
  }, [masjidName, navigate]);

  const [date, setDate] = useState(new Date()); // State for the current date
  // Initial The Prayer Times 
  useEffect(() => {
    if (lat !== "" && long !== "") {
      const PT = new PrayTimes('Makkah');
      const times = PT.getTimes(date, [lat, long], +3);
      
      setPrayerTimes({
        Fajr: convertTo12HourFormat(times.fajr),
        Sunrise: convertTo12HourFormat(times.sunrise),
        Dhuhr: convertTo12HourFormat(times.dhuhr),
        Asr: convertTo12HourFormat(times.asr),
        Maghrib: convertTo12HourFormat(times.maghrib),
        Isha: convertTo12HourFormat(times.isha)
      });

      // Schedule the first Adhan after setting prayer times
      scheduleNextAdhan({
        Fajr: times.fajr,
        Sunrise: times.sunrise,
        Dhuhr: times.dhuhr,
        Asr: times.asr,
        Maghrib: times.maghrib,
        Isha: times.isha
      });
    }

    // Cleanup the interval when component unmounts
    return () => {
      if (adhanIntervalRef.current) {
        clearInterval(adhanIntervalRef.current);
      }
    };
  }, [date ,lat, long]);  // Trigger when lat or long changes
  
  // Change PrayerTimes Every Date Update
  useEffect(() => {
    // Update the date every minute to reflect system changes
    const interval = setInterval(() => {
      const currentSystemDate = new Date();
      setDate(currentSystemDate);
    }, 60 * 1000); // Check every minute

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  // Calculate ikama Time
  useEffect(() => {
    calculateEstablishmentTimes(prayerTimes); // Calculate establishment times
  }, [prayerTimes])

  // Time Real-Time
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      
      // Get the hours, minutes, seconds, and AM/PM
      let hours = now.getHours();
      const minutes = String(now.getMinutes()).padStart(2, '0'); // Ensure two digits for minutes
      const seconds = String(now.getSeconds()).padStart(2, '0'); // Ensure two digits for seconds
      const ampm = hours >= 12 ? 'PM' : 'AM';

      // Convert hours to 12-hour format and remove leading zero
      hours = hours % 12;
      hours = hours ? hours : 12; // Adjust '0' to '12'

      // Construct the time string
      const timeString = `${hours}:${minutes}:${seconds} ${ampm}`;
      
      // Set the formatted time
      setCurrentTime(timeString);

      // Check if it's midnight
      if (hours === 12 && minutes === '00' && seconds === '00' && ampm === 'AM') {
        fetchHijriDate(); // Call the function at midnight
      }
    }, 1000); // Check every second

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [lat, long]); // Include lat and long in the dependency array to ensure the latest values are used

  // Preload Audio 
  useEffect(() => {
    // Preload the audio when the component mounts
    const audio = new Audio(adhanUrl);
    adhanAudioRef.current = audio;

    // You can also preload it by setting preload to 'auto'
    audio.preload = 'auto';
    
    // Optional: Attach a load event to ensure the audio is preloaded
    audio.addEventListener('canplaythrough', () => {
      console.log('Audio is preloaded and ready to play');
    });

    return () => {
      // Clean up the audio reference if the component unmounts
      audio.removeEventListener('canplaythrough', () => {});
    };
  }, [adhanUrl]);

  // Request To Keep App Live
  useEffect(() => {
    // Check if requestIdleCallback is supported
    if ('requestIdleCallback' in window) {
      const idleCallback = () => {
        // Ensure the app stays alive in idle periods by performing minimal tasks
        console.log("App is idle but still alive");
      };
  
      // Request the idle callback to run when the browser is idle
      const idleCallbackId = window.requestIdleCallback(idleCallback, { timeout: 5000 });
  
      // Cleanup function to cancel the idle callback when the component unmounts
      return () => {
        if (idleCallbackId) {
          window.cancelIdleCallback(idleCallbackId);
        }
      };
    }
  }, []);  // Empty dependency array ensures this effect runs only once when the component mounts
  
  // Update Highlited After Update Of The NextAdhen 30mn
  useEffect(() => {
      setTimeout(() => {
      sethighlightedSalet(nextSalet)
    }, 1800000);
  }, [nextSalet])
  
  return (
    <div
      ref={clockRef}
      className="clock"
      onDoubleClick={toggleFullScreen}
      style={{writingMode : !isMobile && screen === "V" ? 'tb' : 'initial' , paddingBottom : !isMobile && screen === "H" ? '1em' : "auto"}}
    >
      
      <Helmet>
          <title>{msqName}</title>
      </Helmet>
      {!isMobile && <img src={backgroundUrl} className='backgroundImage'></img>}
      {isMobile && <img src={require("../assets/mobile.jpg")} className='backgroundImage'></img>}
      
      <nav style={{padding : !isMobile && screen === "V" ? '2em 1.5em' : isMobile  ? ".5em 1em":"1em 2em 0em 2em"}}>
        <img src={require("../assets/logo2.png")} alt="Logo" style={{transform : !isMobile && screen === "V" ? 'rotate(90deg) translateY(35px) translateX(19px)' : 'none',transformOrigin : !isMobile && screen === "V" ? 'right' : 'none'}}/>
        <IoMenu onClick={()=>settoggleMenu(true)} style={{transform : !isMobile && screen === "V" ? 'rotate(90deg) translateY(16px)' : 'none'}}/>
      </nav>

      {showWamidh && <div className='wamidh' id='wamidhHolder'></div>}
      {toggleMenu && <div className='Menu'>
        <nav style={{padding : !isMobile && screen === "V" ? '2em 1.5em' : '1em 1.5em'}}>
          <img src={require("../assets/logo2.png")} alt="Logo" style={{transform : !isMobile && screen === "V" ? 'rotate(90deg) translateY(35px) translateX(19px)' : 'none',transformOrigin : !isMobile && screen === "V" ? 'right' : 'none'}}/>
          <MdOutlineClose onClick={()=>settoggleMenu(false)}  style={{transform : !isMobile && screen === "V" ? 'rotate(90deg) translateY(16px)' : 'none'}}/>
        </nav>
        <div className='insideMenuHolder'>
          <button className='activateSound' style={{backgroundColor : soundActive ? "#c1ff72" : "white"}} onClick={()=>{
            new Audio(clickSound).play();
            setsoundActive(true)

            const resetAdhanAudio = () => {
              adhanAudioRef.current.currentTime = 0; // Reset to start
              adhanAudioRef.current.pause(); // Pause the audio
            };

            adhanAudioRef.current.muted  = true;
            adhanAudioRef.current.play().then(()=>{
              adhanAudioRef.current.muted  = false;
              adhanAudioRef.current.onended = resetAdhanAudio;
            })

            setTimeout(() => {
              settoggleMenu(false)
            }, 2000);

            }}>
            {!soundActive && (<>
              تفعيل الصوت<FiVolumeX /> 
              </>)
            }
            {soundActive && (<>
              تم تفعيل الصوت<FiVolume2 />
              </>)
            }
          </button>

          <button className='activateSound' style={{backgroundColor : "#ffc7c7"}} onClick={()=>{navigate("/")}}>خروج<MdLogout /></button>
        </div>
      </div>}

      <main>
        <h1 className='masjidName' style={{marginLeft : !isMobile && screen === "V" ? '.7em' : '0'}}><MdOutlineMosque style={{transform : !isMobile && screen === "V" ? 'rotate(90deg) ' : 'none'}}/>{msqName}</h1>
        <p className='hijriDate' style={{marginLeft : !isMobile && screen === "V" ? '.5em' : '0'}}>{hijriDate}</p>
        <p className='gregorianDate'>{gregorianDate}</p>
        <p className='currentTime' style={{fontSize : !isMobile && screen === "V" ? '5em' : !isMobile && screen === "H" ? '4.5em' : "2.5em" , marginRight : !isMobile && screen === "V" ? '.3em' : '0' }}>{currentTime}</p>
        <p className='NextAdhen'>{nextAdhenIn}</p>
        <div className='centerHolder' style={{fontWeight : !isMobile && screen === "V" ? '900' : '900' }}>  
          {(screen === "V" || isMobile) && (<table style={{fontSize : isMobile ? "1.5em":'6vh' , borderSpacing : isMobile ? "10px":"40px 15px"}} className="prayer-table-v">
            <thead>
              <tr>
                <th></th>
                <th style={{padding : !isMobile ? ".7em 0" : "0 .7em"}}>الأذان</th>
                <th>الإقامة</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={highlightedSalet == 1 ? "nextSalatActiveV" :'salettdV'} style={{textAlign : "right"}}>الفجر</td>
                <td className={highlightedSalet == 1 ? "nextSalatActiveV" :'salettdV'}>{prayerTimes.Fajr}</td>
                <td className={highlightedSalet == 1 ? "nextSalatActiveV" :'salettdV'}>{establishmentTimes.Fajr}</td>
              </tr>
              <tr>
                <td className={highlightedSalet == 2 ? "nextSalatActiveV" :'salettdV'} style={{textAlign : "right"}}>الشروق</td>
                <td className={highlightedSalet == 2 ? "nextSalatActiveV" :'salettdV'}>{prayerTimes.Sunrise}</td>
                <td className={highlightedSalet == 2 ? "nextSalatActiveV" :'salettdV'}>{establishmentTimes.Sunrise}</td>
              </tr>
              <tr>
                <td className={highlightedSalet == 3 ? "nextSalatActiveV" :'salettdV'} style={{textAlign : "right"}}>الظهر</td>
                <td className={highlightedSalet == 3 ? "nextSalatActiveV" :'salettdV'}>{prayerTimes.Dhuhr}</td>
                <td className={highlightedSalet == 3 ? "nextSalatActiveV" :'salettdV'}>{establishmentTimes.Dhuhr}</td>
              </tr>
              <tr>
                <td className={highlightedSalet == 4 ? "nextSalatActiveV" :'salettdV'} style={{textAlign : "right"}}>العصر</td>
                <td className={highlightedSalet == 4 ? "nextSalatActiveV" :'salettdV'}>{prayerTimes.Asr}</td>
                <td className={highlightedSalet == 4 ? "nextSalatActiveV" :'salettdV'}>{establishmentTimes.Asr}</td>
              </tr>
              <tr>
                <td className={highlightedSalet == 5 ? "nextSalatActiveV" :'salettdV'} style={{textAlign : "right"}}>المغرب</td>
                <td className={highlightedSalet == 5 ? "nextSalatActiveV" :'salettdV'}>{prayerTimes.Maghrib}</td>
                <td className={highlightedSalet == 5 ? "nextSalatActiveV" :'salettdV'}>{establishmentTimes.Maghrib}</td>
              </tr>
              <tr>
                <td className={highlightedSalet == 6 ? "nextSalatActiveV" :'salettdV'} style={{textAlign : "right"}}>العشاء</td>
                <td className={highlightedSalet == 6 ? "nextSalatActiveV" :'salettdV'}>{prayerTimes.Isha}</td>
                <td className={highlightedSalet == 6 ? "nextSalatActiveV" :'salettdV'}>{establishmentTimes.Isha}</td>
              </tr>
            </tbody>
          </table>)}
          {(screen  === "H" && !isMobile) && (<table style={{fontSize : isMobile ? "1.5em":'5.8vh'}} className='prayer-table-h'>
            <thead>
              <tr>
                <th></th>
                <th className={highlightedSalet == 1 ? "nextSalatActiveH" :'salettd'} >الفجر</th>
                <th className={highlightedSalet == 2 ? "nextSalatActiveH" :'salettd'} >الشروق</th>
                <th className={highlightedSalet == 3 ? "nextSalatActiveH" :'salettd'} >الظهر</th>
                <th className={highlightedSalet == 4 ? "nextSalatActiveH" :'salettd'} >العصر</th>
                <th className={highlightedSalet == 5 ? "nextSalatActiveH" :'salettd'} >المغرب</th>
                <th className={highlightedSalet == 6 ? "nextSalatActiveH" :'salettd'} >العشاء</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>الأذان</th>
                <th className={highlightedSalet == 1 ? "nextSalatActiveH" :'salettd'}>{prayerTimes.Fajr}</th>
                <th className={highlightedSalet == 2 ? "nextSalatActiveH" :'salettd'}>{prayerTimes.Sunrise}</th>
                <th className={highlightedSalet == 3 ? "nextSalatActiveH" :'salettd'}>{prayerTimes.Dhuhr}</th>
                <th className={highlightedSalet == 4 ? "nextSalatActiveH" :'salettd'}>{prayerTimes.Asr}</th>
                <th className={highlightedSalet == 5 ? "nextSalatActiveH" :'salettd'}>{prayerTimes.Maghrib}</th>
                <th className={highlightedSalet == 6 ? "nextSalatActiveH" :'salettd'}>{prayerTimes.Isha}</th>
              </tr>
              <tr>
                <th>الإقامة</th>
                <th className={highlightedSalet == 1 ? "nextSalatActiveH" :'salettd'}>{establishmentTimes.Fajr}</th>
                <th className={highlightedSalet == 2 ? "nextSalatActiveH" :'salettd'}>{establishmentTimes.Sunrise}</th>
                <th className={highlightedSalet == 3 ? "nextSalatActiveH" :'salettd'}>{establishmentTimes.Dhuhr}</th>
                <th className={highlightedSalet == 4 ? "nextSalatActiveH" :'salettd'}>{establishmentTimes.Asr}</th>
                <th className={highlightedSalet == 5 ? "nextSalatActiveH" :'salettd'}>{establishmentTimes.Maghrib}</th>
                <th className={highlightedSalet == 6 ? "nextSalatActiveH" :'salettd'}>{establishmentTimes.Isha}</th>
              </tr>
            </tbody>
          </table>)}
        </div>
        <div className='blackScreen' style={{writingMode : screen === "V" ? "vertical-rl" : "unset"}} id='blackscreen'>
              {currentTime}
        </div>
        <div className='bottomHolder' style={{
            paddingLeft : !isMobile && screen === "V" ? '2em' : !isMobile && screen === "H"  ? '1em' : isMobile  ? '1.7em' : "0",
            paddingTop : !isMobile && screen === "H" ? '0em' : "auto"
          }}>
          <h2 className='ads'>{ads}</h2>
          <h3 className='hadeeth'>{hadeeth}</h3>
        </div>

        {/* Audio for Adhan */}
        {adhanUrl && <audio ref={adhanAudioRef} className='audioHolder' preload='auto' src={adhanUrl} controls />}
      </main>
    </div>
  );
}



export default Clock;
